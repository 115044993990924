.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
}

.cell {
  border-width: 1px;
  border-style: solid;
  border-color: black;
  display: flex;
  background-color: white;
  margin: -1px;
}

.grid {
  display: flex;
  height: 60vw;
  flex-wrap: wrap;
  width: 60vw;
}

.options {
  width: 35vw;
  display: flex;
  flex-direction: column;
}

.main {
  background-color: #282c34;
  color: white;
  display: flex;
}

.title {
  font-size:larger;
}